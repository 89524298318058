import React, { useState } from 'react';

const MyExperiences = ({ experiences = [], userEmail, onClose, onSaveExperience }) => {
  const [editingExperienceId, setEditingExperienceId] = useState(null);
  const [editedExperience, setEditedExperience] = useState({
    description: '',
    whatWorked: '',
    whatDidNotWork: '',
  });

  const userExperiences = experiences.filter(experience => experience.userEmail === userEmail);

  const handleEdit = (experience) => {
    setEditingExperienceId(experience.id);
    setEditedExperience({
      description: experience.description,
      whatWorked: experience.whatWorked,
      whatDidNotWork: experience.whatDidNotWork,
    });
  };

  const handleSave = () => {
    if (editedExperience.description && editedExperience.whatWorked && editedExperience.whatDidNotWork) {
      onSaveExperience(editingExperienceId, editedExperience);
      setEditingExperienceId(null);
    } else {
      alert('All fields are required before saving!');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedExperience((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="user-experience-list">
      <button onClick={onClose}>Close My Experiences</button>
      <h2>Your Experiences</h2>

      {userExperiences.length > 0 ? (
        userExperiences.map((experience) => (
          <div key={experience._id || experience.id} className="experience">
            {editingExperienceId === experience.id ? (
              <>
                <textarea
                  name="description"
                  value={editedExperience.description}
                  onChange={handleChange}
                />
                <textarea
                  name="whatWorked"
                  value={editedExperience.whatWorked}
                  onChange={handleChange}
                />
                <textarea
                  name="whatDidNotWork"
                  value={editedExperience.whatDidNotWork}
                  onChange={handleChange}
                />
                <button onClick={handleSave}>Save</button>
              </>
            ) : (
              <>
                <p><strong>Description:</strong> {experience.description}</p>
                <p><strong>What worked:</strong> {experience.whatWorked}</p>
                <p><strong>What didn't work:</strong> {experience.whatDidNotWork}</p>
                <button onClick={() => handleEdit(experience)}>Edit</button>
              </>
            )}
          </div>
        ))
      ) : (
        <p>You have not submitted any experiences.</p>
      )}
    </div>
  );
};

export default MyExperiences;
