import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import ExperienceList from './components/ExperienceList';
import AddExperienceForm from './components/AddExperienceForm';
import MyExperiences from './components/MyExperiences';
import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';

function App() {
  const [experiences, setExperiences] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [token, setToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loggedInUserEmail, setLoggedInUserEmail] = useState(null);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showUserExperiences, setShowUserExperiences] = useState(false);

  const API_URL = 'https://fastfixsolution.com'; // Update to use your actual domain

  useEffect(() => {
    fetchExperiences();
  }, [searchTerm]);

  // Fetch experiences from API
  const fetchExperiences = async () => {
    try {
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      let query = `${API_URL}/api/experiences?search=${encodeURIComponent(searchTerm)}`;

      const response = await fetch(query, { headers });
      const data = await response.json();
      setExperiences(data);
    } catch (error) {
      console.error('Error fetching experiences', error);
      alert('Error fetching experiences');
    }
  };

  const handleLogin = async (email, password) => {
    try {
      const response = await fetch(`${API_URL}/api/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();
      if (data.token) {
        setToken(data.token);
        setLoggedInUserEmail(data.email);
        setIsLoggedIn(true);
        setShowLogin(false);
      } else {
        alert('Invalid login credentials');
      }
    } catch (error) {
      console.error('Error during login', error);
      alert('Error during login');
    }
  };

  const handleRegister = async (email, password) => {
    try {
      const response = await fetch(`${API_URL}/api/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        alert('Registration successful, please log in');
        setShowRegister(false);
      } else {
        alert('Registration failed');
      }
    } catch (error) {
      console.error('Error during registration', error);
      alert('Error during registration');
    }
  };

  const handleLogout = () => {
    setToken(null);
    setIsLoggedIn(false);
    setLoggedInUserEmail(null);
  };

  const handleAddExperience = async (experience) => {
    try {
      const headers = token
        ? {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        : { 'Content-Type': 'application/json' };

      const response = await fetch(`${API_URL}/api/experiences`, {
        method: 'POST',
        headers,
        body: JSON.stringify(experience),
      });

      if (response.ok) {
        await fetchExperiences();
        setShowForm(false);
      } else {
        const errorData = await response.json();
        alert(errorData.message || 'Failed to add experience.');
      }
    } catch (error) {
      console.error('Error while adding the experience', error);
      alert('Error while adding the experience');
    }
  };

  // Function to save edited experience
  const handleSaveExperience = async (id, updatedExperience) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      const response = await fetch(`${API_URL}/api/experiences/${id}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(updatedExperience),
      });

      if (response.ok) {
        await fetchExperiences(); // Refresh the experience list after updating
        alert('Experience updated successfully');
      } else {
        alert('Failed to update the experience');
      }
    } catch (error) {
      console.error('Error while updating the experience', error);
      alert('Error while updating the experience');
    }
  };

  return (
    <div className="App">
      <Header
        isLoggedIn={isLoggedIn}
        onLoginClick={() => setShowLogin(true)}
        onRegisterClick={() => setShowRegister(true)}
        onLogoutClick={handleLogout}
        onViewMyExperiences={() => setShowUserExperiences(true)}
      />

      {!showUserExperiences ? (
        <>
          <ExperienceList
            experiences={experiences}
            searchTerm={searchTerm}
            handleSearch={setSearchTerm}
          />
          <button onClick={() => setShowForm(true)} className="add-button">
            Add Experience
          </button>
        </>
      ) : (
        <MyExperiences
          experiences={experiences}
          userEmail={loggedInUserEmail}
          onClose={() => setShowUserExperiences(false)}
          onSaveExperience={handleSaveExperience} // Pass the save function as a prop
        />
      )}

      {showForm && <AddExperienceForm onSubmit={handleAddExperience} onCancel={() => setShowForm(false)} />}
      {showLogin && <LoginForm onLogin={handleLogin} onCancel={() => setShowLogin(false)} />}
      {showRegister && <RegisterForm onRegister={handleRegister} onCancel={() => setShowRegister(false)} />}
    </div>
  );
}

export default App;
