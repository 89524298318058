import React, { useEffect, useState } from 'react';
import { translateText } from './translate'; // Vertaalfunctie importeren

// Functie om punten te verzamelen van alle gefilterde ervaringen
const gatherPoints = (experiences, type) => {
  const allPoints = experiences.flatMap((experience) => {
    const points = type === 'worked' ? experience.whatWorked : experience.whatDidNotWork;
    // Split alleen op '/' of '\' en verwijder dubbele punten
    return points ? points.split(/[\/\\]/) : []; // Gebruik regex om op '/' of '\' te splitsen
  });
  return [...new Set(allPoints.map((point) => point.trim()))]; // Verwijder dubbele en trim whitespaces
};

const ExperienceList = ({ experiences, searchTerm, handleSearch }) => {
  const [translatedExperiences, setTranslatedExperiences] = useState([]); // Status voor vertaalde ervaringen
  const [userLang, setUserLang] = useState('en'); // Standaardtaal Engels als fallback
  const [loadingTranslations, setLoadingTranslations] = useState(false); // Laadstatus voor vertalingen

  // Functie om ervaringen te vertalen naar de voorkeurstaal van de gebruiker
  const translateExperiences = async (experiences) => {
    setLoadingTranslations(true); // Vertalingen worden nu geladen
    const translated = await Promise.all(
      experiences.map(async (experience) => {
        const translatedDescription = await translateText(experience.description, userLang);
        const translatedWhatWorked = await translateText(experience.whatWorked, userLang);
        const translatedWhatDidNotWork = await translateText(experience.whatDidNotWork, userLang);

        return {
          ...experience,
          description: translatedDescription,
          whatWorked: translatedWhatWorked,
          whatDidNotWork: translatedWhatDidNotWork
        };
      })
    );
    setTranslatedExperiences(translated); // Bijwerken van de vertaalde ervaringen
    setLoadingTranslations(false); // Vertalingen zijn klaar
  };

  // Detecteer de taal van de gebruiker en vertaal ervaringen
  useEffect(() => {
    // Detecteer de voorkeurstaal van de gebruiker, standaard op Engels ('en') als geen taal wordt gevonden
    const lang = navigator.language ? navigator.language.split('-')[0] : 'en';  // Bijvoorbeeld 'nl' of 'en'
    
    // Fallback naar Engels als de taal niet wordt herkend
    const supportedLanguages = ['en', 'nl', 'fr', 'de']; // Voeg hier meer ondersteunde talen toe
    const detectedLang = supportedLanguages.includes(lang) ? lang : 'en';
    
    setUserLang(detectedLang); // Stel de gedetecteerde of fallback-taal in

    if (searchTerm) {
      const filteredExperiences = experiences.filter((experience) =>
        experience.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
      translateExperiences(filteredExperiences); // Vertaal de gefilterde ervaringen
    }
  }, [searchTerm, experiences]);

  // Verwerk de strings om een gecombineerde lijst van punten te krijgen
  const whatWorkedPoints = gatherPoints(translatedExperiences, 'worked');
  const whatDidNotWorkPoints = gatherPoints(translatedExperiences, 'didntWork');

  return (
    <>
      {/* Zoekbalk */}
      <div className="search-container">
        <input
          className="search-bar"
          type="text"
          placeholder="Enter your issue to search..."
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>

      {/* Toon resultaat als er een zoekterm is */}
      {loadingTranslations ? (
        <p>Loading translations...</p>
      ) : searchTerm && translatedExperiences.length > 0 ? (
        <div className="summary-container">
          <div className="worked-summary">
            <h2>What Worked</h2>
            <ul>
              {whatWorkedPoints.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </div>

          <div className="didnt-work-summary">
            <h2>What Didn't Work</h2>
            <ul>
              {whatDidNotWorkPoints.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <p>Enter a search term to find relevant experiences.</p>
      )}
    </>
  );
};

export default ExperienceList;
