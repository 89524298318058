import axios from 'axios';

// Google Translate API-sleutel
const API_KEY = 'AIzaSyBtjO7b-88SdYQuDRfhVmbIn8-xDN6HSfk';

// Functie om tekst te vertalen naar een doeltaal
export const translateText = async (text, targetLang) => {
  try {
    const response = await axios.post(`https://translation.googleapis.com/language/translate/v2`, {}, {
      params: {
        q: text,  // De tekst die je wilt vertalen
        target: targetLang,  // Doeltaal (bijv. 'nl' voor Nederlands)
        key: API_KEY  // Jouw Google Translate API-sleutel
      }
    });
    return response.data.data.translations[0].translatedText;  // Geeft de vertaalde tekst terug
  } catch (error) {
    console.error('Vertaalfout:', error);
    return text;  // Als de vertaling mislukt, retourneer de originele tekst
  }
};
