import React from 'react';

const Header = ({ isLoggedIn, onLoginClick, onRegisterClick, onLogoutClick, onViewMyExperiences }) => {
  return (
    <header className="App-header">
      <div className="auth-buttons">
        {!isLoggedIn ? (
          <>
            <button onClick={onLoginClick}>Login</button>
            <button onClick={onRegisterClick}>Register</button>
          </>
        ) : (
          <>
            <button onClick={onLogoutClick} className="logout-button">Logout</button>
            <button onClick={onViewMyExperiences} className="my-experiences-button">My Experiences</button>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
