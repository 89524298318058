import React, { useState } from 'react';

const LoginForm = ({ onLogin, onCancel }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <div className="overlay">
      <div className="overlay-content">
        <h2>Login</h2>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={() => onLogin(email, password)}>Login</button>
        <button onClick={onCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default LoginForm;
