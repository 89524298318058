import React, { useState } from 'react';

const AddExperienceForm = ({ onSubmit, onCancel }) => {
  const [description, setDescription] = useState('');
  const [whatWorked, setWhatWorked] = useState('');
  const [whatDidNotWork, setWhatDidNotWork] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      description,
      whatWorked,
      whatDidNotWork,
      isAnonymous,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Add Experience</h2>
      <label>
        Description:
        <textarea value={description} onChange={(e) => setDescription(e.target.value)} required />
      </label>
      <label>
        What Worked:
        <input type="text" value={whatWorked} onChange={(e) => setWhatWorked(e.target.value)} required />
      </label>
      <label>
        What Didn't Work:
        <input type="text" value={whatDidNotWork} onChange={(e) => setWhatDidNotWork(e.target.value)} required />
      </label>
      <label>
        Add Anonymously:
        <input type="checkbox" checked={isAnonymous} onChange={(e) => setIsAnonymous(e.target.checked)} />
      </label>
      <button type="submit">Submit</button>
      <button type="button" onClick={onCancel}>Cancel</button>
    </form>
  );
};

export default AddExperienceForm;
